import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AccountService } from './configuration-management/services/account.service';
import { Router } from '@angular/router';
import { User } from './configuration-management/models/user.interface';
import { SyncService } from './shared/offline/sync-service';
import { UserIdleService } from 'angular-user-idle';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { LoginDialogComponent } from './layout/landing-page/login-dialog/login.dialog';
import { Subscription } from 'rxjs';
import { ConfigLoaderService } from './config-loader.service';
import { storage } from './shared/local-storage/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentUser: User;
  dialogRef: MatDialogRef<LoginDialogComponent, any> | null;
  events: string[] = [];
  opened = true;
  shouldRun = true;
  subscription = Subscription;
  syncFinished = false;
  readonly idleSeconds = 8 * 60 * 60; // 8 hours

  constructor(
    public swUpdate: SwUpdate,
    private accountService: AccountService,
    private router: Router,
    private syncService: SyncService,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    public configService: ConfigLoaderService,
  ) {

    this.accountService.currentUser.subscribe(x => {
      this.currentUser = x;
      storage.setItem('show-login', this.currentUser === null);
      if (this.currentUser === null) {
        this.showLoginForm();
      }
    });

    this.userIdle.setConfigValues({
      idle: this.idleSeconds
    });
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      if (count) {
        console.log(`user idle for ${this.idleSeconds} seconds - showing login`);
      }
    });
    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => this.showLoginForm());


    if (this.currentUser != null) {
      if (this.currentUser.keepLoggedIn === true) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
      }
    }


  }

  ngOnInit() {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A newer version of the application is available. Load the new version ?')) {
          window.location.reload();
        }
      });
    }

  }


  showLoginForm() {

    if (this.dialogRef) {
      return;
    }

    storage.setItem('show-login', true);
    this.dialogRef = this.dialog.open(LoginDialogComponent, {
      disableClose: true,
      height: 'auto',
      width: 'auto',
      panelClass: 'login-panel'
    });

    this.dialogRef.afterClosed().subscribe(res => {
      if (this.currentUser.keepLoggedIn === true) {
        this.userIdle.stopWatching();
        this.userIdle.stopTimer();
      }
      else {
        this.userIdle.resetTimer();
      }
      this.dialogRef = null;
    });

  }



  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('login');
  }

}
